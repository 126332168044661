<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="dialogConfirm" max-width="600px">
      <v-card>
        <v-card-title
          style="font-family: poppinsregular; font-size: 15px; color: #000"
          >Are you sure you want to cancel this order?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text small @click="dialogConfirm = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text small @click="dialogCancel=true,dialogConfirm = false"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancel" max-width="600px">
      <v-card>
        <!-- <v-card-title
          style="font-family: poppinsregular; font-size: 15px; color: #000"
          >Are you sure you want to cancel this order?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text small @click="dialogCancel = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text small @click="cancelOrder()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions> -->
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-card tile flat>
              <v-layout
                wrap
                justify-center
                px-8
                py-5
                style="background-color: #b6b6b626"
              >
                <v-flex xs11 text-left>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 18px;
                      color: #393939;
                    "
                  >
                    Please Provide Bank Details
                  </span>
                </v-flex>
                <v-flex xs1>
                  <v-icon @click="dialogCancel = false" color="#000000"
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                pt-8
                px-5
                style="
                  font-size: 14px;
                  color: #1f1f1f;
                  font-family: RobotoRegular;
                "
              >
                <v-flex xs12 sm12 text-left px-2>
                  <span>Bank Name </span>
                  <v-text-field
                    ref="bankName"
                    background-color="#F5F5F5"
                    solo
                    flat
                    dense
                    :rules="[rules.required]"
                    v-model="bankName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 text-left px-2>
                  <span>Account Holder Name </span>
                  <v-text-field
                    ref="accountHolderName"
                    background-color="#F5F5F5"
                    solo
                    flat
                    dense
                    :rules="[rules.required]"
                    v-model="accountHolderName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 text-left px-2>
                  <span> Account Number </span>
                  <v-text-field
                    ref="accountNo"
                    background-color="#F5F5F5"
                    solo
                    flat
                    dense
                    :rules="[rules.required]"
                    v-model="accountNo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 text-left px-2>
                  <span> IFSC Code </span>
                  <v-text-field
                    ref="ifscCode"
                    background-color="#F5F5F5"
                    solo
                    flat
                    dense
                    :rules="[rules.required]"
                    v-model="ifscCode"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 text-left px-2>
                  <span> Phone Number </span>
                  <v-text-field
                    ref="phoneNo"
                    background-color="#F5F5F5"
                    solo
                    flat
                    dense
                    type="number"
                    :rules="[rules.required]"
                    v-model="phoneNo"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end py-5>
                <v-flex xs6 px-2 text-right>
                  <v-btn
                    :ripple="false"
                    depressed
                    block
                    tile
                    color="#30B868"
                    @click="validateInput"
                  >
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                      "
                    >
                      Cancel Order
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >Order Details</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-2>
          <v-flex xs12 py-4>
            <v-card>
              <v-layout wrap justify-start py-3 px-10>
                <v-flex xs12 py-2>
                  <v-layout wrap justify-space-between>
                    <v-flex xs6 sm6 md3 lg3 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Number
                          </span>
                        </v-flex>
                        <v-flex xs12 v-if="orders.orderId">
                          <span class="orderText">{{ orders.orderId }} </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm6 md3 lg3>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Date
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span class="orderText"
                            >{{ formatDate(orders.create_date) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm6 md2 lg3>
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Status
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            v-if="orders.orderStatus == 'Active'"
                            style="color: #30b868; font-family: poppinsmedium"
                            >Order Placed</span
                          >
                          <span
                            v-else
                            style="color: #30b868; font-family: poppinsmedium"
                            >{{ orders.orderStatus }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs6
                      sm6
                      md2
                      lg3
                      text-right
                      align-self-center
                      v-if="
                        orders.orderStatus == 'Pending' ||
                        orders.orderStatus == 'Active' ||
                        orders.orderStatus == 'Accepted'
                      "
                    >
                      <v-layout wrap justify-end>
                        <v-flex xs12>
                          <!-- <span
                            style="color: #7e8f85; font-family: poppinsregular"
                            >Order Cancel
                          </span> -->
                        </v-flex>
                        <v-flex xs12>
                          <v-btn
                            depressed
                            color="red"
                            dark
                            @click="dialogConfirm = true"
                            ><span style="font-family: poppinsmedium"
                              >Cancel Order</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-6>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap v-if="orders.deliveryAddress">
                    <v-flex xs12 sm6 md8 text-left>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span class="orderHeading">Customer Details</span>
                        </v-flex>
                        <v-flex xs12 pt-2>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Name :
                          </span>
                          <span class="orderText">{{
                            orders.deliveryAddress.name
                          }}</span>
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              color: #7e8f85;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            >Phone :
                          </span>
                          <span class="orderText">{{
                            orders.deliveryAddress.phone
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-layout wrap justify-end>
                        <v-flex xs12 sm8 text-left>
                          <span class="orderHeading">Shipping Address</span>
                        </v-flex>
                        <v-flex xs12 sm8 pt-2 text-left>
                          <span class="orderText"
                            >{{ orders.deliveryAddress.address }},
                            {{ orders.deliveryAddress.city }},
                            {{ orders.deliveryAddress.district }},
                            {{ orders.deliveryAddress.country }},
                            {{ orders.deliveryAddress.pincode }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 py-6>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 text-left pb-4>
                  <span class="orderHeading">Products </span>
                </v-flex>
                <v-flex xs12 py-2 v-for="(item, i) in products" :key="i">
                  <v-layout wrap justify-start>
                    <v-flex xs2 text-left v-if="item.productId">
                      <v-card outlined>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-img
                              :src="mediaUURL + item.productId.photos[0]"
                              height="100%"
                              width="auto"
                              contain
                              :alt="item.name"
                            >
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex xs9 pl-2>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12 sm12 md12 align-self-center text-left>
                          <v-layout
                            wrap
                            justify-start
                            style="line-height: 16px"
                            fill-height
                            v-if="item.productId"
                          >
                            <v-flex xs12>
                              <router-link
                                :to="'/productDetails?id=' + item.itemId._id"
                              >
                                <span
                                  style="
                                    font-family: poppinssemibold;
                                    font-size: 15px;
                                    color: #191919;
                                  "
                                >
                                  {{ item.productId.productname }}
                                  <!-- <span v-if="orders.name.length > 25"> ... </span> -->
                                </span>
                              </router-link>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="item.itemId">
                              <v-layout wrap>
                                <v-flex xs12 pt-3>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Size
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.itemId.size }}</span
                                    >
                                  </span>
                                </v-flex>
                                <v-flex xs12 pt-1>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >OTY
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.quantity }}
                                    </span>
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 sm6 pt-2 text-right v-if="item.itemId">
                              <v-layout wrap>
                                <v-flex xs12 pt-1 v-if="item.taxPercentage>0">
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Taxable Amount
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.taxableAmount.toFixed(2) }}
                                    </span>
                                  </span>
                                </v-flex>
                                <v-flex xs12 pt-1 v-if="item.taxPercentage>0">
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Tax Amount
                                    <span style="font-family: poppinsregular"
                                      >: {{ item.taxAmount.toFixed(2) }}
                                    </span>
                                  </span>
                                </v-flex>
                                <v-flex xs12>
                                  <span
                                    style="
                                      color: #191919;
                                      font-size: 13px;
                                      font-family: poppinsmedium;
                                    "
                                    >Total Amount
                                    <span style="font-family: poppinssemibold"
                                      >: ₹ {{ item.quantity * item.itemId.offerPrice }}
                                    </span>
                                  </span>
                                  <!-- <span
                                    style="
                                      color: #383838;
                                      font-family: poppinssemibold;
                                    "
                                    > {{ item.quantity }} x ₹ {{ item.itemId.offerPrice }}</span
                                  > -->
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 text-left py-4>
                  <span class="orderHeading">Order Details </span>
                </v-flex>
                <v-flex xs12 pb-4>
                  <v-card flat color="#F3F3F3">
                    <v-layout wrap pa-5>
                      <v-flex xs12 v-if="orders.igstTotal>0">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total Taxable Amount</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.taxableAmount.toFixed(2) }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1 v-if="orders.igstTotal>0">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total Tax</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.igstTotal.toFixed(2) }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Price</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.totalAmount }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1 v-if="orders.isPromoCodeApplied">
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Promo Code</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.couponDiscount }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-1>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Delivery Charges</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.deliveryCharge }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs12 pt-1>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span class="orderText">Total tax</span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span class="orderText"
                              >₹ {{ orders.taxAmount }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex> -->
                      <v-flex xs12 py-4>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs6 text-left>
                            <span
                              style="
                                color: #000000;
                                font-family: poppinssemibold;
                              "
                              >Total Amount
                            </span>
                          </v-flex>
                          <v-flex xs6 text-right>
                            <span
                              style="
                                color: #000000;
                                font-family: poppinssemibold;
                              "
                              >₹ {{ orders.subTotal }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  text-right
                  v-if="orders.orderStatus == 'Delivered'"
                >
                  <a
                    target="_blank"
                    :href="ipURL + '/recipt?id=' + orders._id"
                    class="downlink"
                  >
                    <span
                      style="
                        color: #30b868;
                        font-family: poppinsregular;
                        font-size: 13px;
                        text-decoration: underline;
                      "
                    >
                      <v-icon small color="#30B868"
                        >mdi-download-outline</v-icon
                      >
                      DOWNLOAD INVOICE</span
                    >
                  </a>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      dialogConfirm: false,
      dialogCancel: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      cart: {
        cart: [],
      },
      bankName: "",
      accountHolderName: "",
      accountNo: "",
      ifscCode: "",
      phoneNo: "",
      searchKey: null,
      pincode: null,
      orders: {},
      products: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    form() {
      return {
        bankName: this.bankName,
        accountHolderName: this.accountHolderName,
        accountNo: this.accountNo,
        ifscCode: this.ifscCode,
        phoneNo: this.phoneNo,
      };
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    validateInput() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (this.formHasErrors == false) this.cancelOrder();
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchaseView",
        data: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.orders = response.data.data;
          this.products = response.data.items;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    cancelOrder() {
      axios({
        method: "POST",
        url: "/purchase/cancel",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          bankName: this.bankName,
          accountHolderName: this.accountHolderName,
          accountNo: this.accountNo,
          ifscCode: this.ifscCode,
          phoneNo: this.phoneNo,
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.dialogCancel = false;
            this.msg = "Order Cancelled";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>